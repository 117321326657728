var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pl-sm-16",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.auctions,"search":_vm.search,"loading":_vm.api.isLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"teal--text"},[_vm._v(" Auctions ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogCancel),callback:function ($$v) {_vm.dialogCancel=$$v},expression:"dialogCancel"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Are you sure you want to hide this auction? ")]),_c('v-card-text',{staticClass:"pa-5"},[_c('div',{staticClass:"grey lighten-5 rounded-lg pa-5 text-uppercase"},[_vm._v(" "+_vm._s(_vm.selectedItem.seller)+" - "+_vm._s(_vm.selectedItem.mobile_number)+" "),_c('div',{staticClass:"teal--text"},[_vm._v(" Description: "+_vm._s(_vm.selectedItem.description)+" ")])])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"teal darken-1","text":""},on:{"click":function($event){_vm.dialogCancel = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.hide}},[_vm._v(" Yes ")]),_c('v-spacer')],1)],1)],1)],1),_c('v-card-title',[_c('v-text-field',{staticClass:"mb-3",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.image_url",fn:function({ item }){return [_c('v-avatar',{attrs:{"size":"40","tile":""}},[_c('v-img',{attrs:{"src":item.image_url,"alt":"Image"}})],1)]}},{key:"item.bids",fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":() => {
            _vm.$router.push({
              name: 'PageDashboardBids',
              params: { id: item.id },
              query: { status: item.status },
            });
          }}},[_vm._v(" Check Bids ")])]}},{key:"item.views",fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary","text":""},on:{"click":() => {
            _vm.$router.push({
              name: 'PageDashboardViews',
              params: { id: item.id },
              query: { status: item.status },
            });
          }}},[_vm._v(" Check Views ")])]}},{key:"item.actions",fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"red","text":""},on:{"click":function($event){_vm.selectedItem = item;
          _vm.dialogCancel = true;}}},[_vm._v(" Hide ")])]}}])}),_c('v-snackbar',{attrs:{"timeout":_vm.timeout,"text":""},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"color":"teal","text":""},on:{"click":function($event){_vm.api.isError = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.api.isError),callback:function ($$v) {_vm.$set(_vm.api, "isError", $$v)},expression:"api.isError"}},[_vm._v(" "+_vm._s(_vm.api.error)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }