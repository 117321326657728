<template>
  <v-container class="pl-sm-16" fluid>
    <v-data-table
      :headers="headers"
      :items="auctions"
      :search="search"
      :loading="api.isLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="teal--text"> Auctions </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogCancel" max-width="500px">
            <v-card>
              <v-card-title>
                Are you sure you want to hide this auction?
              </v-card-title>
              <v-card-text class="pa-5">
                <div
                  class="grey lighten-5 rounded-lg pa-5 text-uppercase"
                >
                  {{ selectedItem.seller }} - {{ selectedItem.mobile_number }}

                  <div class="teal--text">
                    Description:  {{ selectedItem.description }}
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="dialogCancel = false">
                  Cancel
                </v-btn>
                <v-btn color="red darken-1" text @click="hide"> Yes </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-3"
          ></v-text-field>
        </v-card-title>
      </template>

      <!-- <template v-slot:item.status="{ item }">
        <v-chip
          :color="getColor(item.status)"
          dark
          style="width: 80px"
          class="justify-center"
        >
          {{ item.status }}
        </v-chip>
        <v-btn
          text
          x-small
          v-if="item.status == 'ACTIVE' && item.access != 'Admin'"
          color="grey"
          class="mx-2 text-capitalize"
          @click="
            selectedItem = item;
            dialogDeactivate = true;
          "
        >
          Deactivate
        </v-btn>
        <v-btn
          text
          x-small
          v-else-if="item.access != 'Admin'"
          class="mx-2 text-capitalize"
          @click="
            selectedItem = item;
            dialogActivate = true;
          "
        >
          Activate
        </v-btn>
      </template> -->
      <template v-slot:item.image_url="{ item }">
        <v-avatar size="40" tile>
          <v-img :src="item.image_url" alt="Image"></v-img>
        </v-avatar>
      </template>
      <template v-slot:item.bids="{ item }">
        <v-btn
          small
          color="primary"
          text
          @click="
            () => {
              $router.push({
                name: 'PageDashboardBids',
                params: { id: item.id },
                query: { status: item.status },
              });
            }
          "
        >
          Check Bids
        </v-btn>
      </template>
      <template v-slot:item.views="{ item }">
        <v-btn
          small
          color="primary"
          text
          @click="
            () => {
              $router.push({
                name: 'PageDashboardViews',
                params: { id: item.id },
                query: { status: item.status },
              });
            }
          "
        >
          Check Views
        </v-btn>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          color="red"
          text
          @click="
            selectedItem = item;
            dialogCancel = true;
          "
        >
          Hide
        </v-btn>
      </template>
    </v-data-table>
    <v-snackbar v-model="api.isError" :timeout="timeout" text>
      {{ api.error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="teal" text v-bind="attrs" @click="api.isError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  watch: {
    //
  },

  created() {
    this.initialize();
  },
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    dialogCancel: false,
    timeout: 2000,
    search: "",
    headers: [
      { text: "Image", value: "image_url" },
      { text: "Seller", value: "seller" },
      { text: "Mobile", value: "mobile_number" },
      { text: "Description", value: "description" },
      { text: "Item Weight", value: "item_weight" },
      { text: "Starting Bid Price", value: "starting_bid_price" },
      { text: "Created at", value: "created_at" },
      { text: "End at", value: "scheduled_end_at" },
      { text: "Status", value: "status" },
      { text: "Auction Views", value: "view_count" },
      { text: "Bids", value: "bids", sortable: false, align: "center" },
      { text: "Views", value: "views", sortable: false, align: "center" },
      { text: "Action", value: "actions", sortable: false, align: "center" },
    ],
    auctions: [],
    selectedItem: {
      seller: "",
      mobile_number: "",
      description: "",
    },
  }),
  mounted() {
    //
  },
  methods: {
    initialize() {
      this.api.url = this.$api.servers.bidding + "/v1/en/admin/auction/all";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
        console.log(e);
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        console.log(resp);
        this.auctions = [];
        resp.forEach((element) => {
          this.auctions.push({
            id: element.id,
            image_url: element.auction_images[0]
              ? element.auction_images[0].image_url
              : null,
            seller: element.users.name,
            mobile_number: element.users.mobile_number,
            status: element.status,
            starting_bid_price: element.starting_bid_price,
            created_at: element.created_at,
            scheduled_end_at: element.scheduled_end_at,
            item_weight: element.item_weight,
            description: element.description,
            view_count: element._count.auction_views
          });
        });
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },
    hide() {
      this.api.url = this.$api.servers.bidding + "/v1/en/admin/auction/delete";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
        this.initialize();
        this.dialogCancel = false;
      };
      this.api.params = {
        id: this.selectedItem.id,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },
  },
};
</script>

<style>
.name-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.8) 0%,
    rgba(12, 186, 189, 0.8) 100%
  );
  color: white !important;
}

.user-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.aution-icon {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 50px !important;
  position: absolute !important;
}
</style>